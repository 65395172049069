import { useSubscribe } from "replicache-react";
import { Reflect } from "@workcanvas/reflect/client";
import { ReadTransaction } from "@workcanvas/reflect";
import { canvasElementPrefix, canvasMetadataPrefix, sanityMutationId } from "../shared/util/utils";
import { clientStatePrefix, getClientState } from "../shared/datamodel/client-state";
import { connectorPrefix } from "../shared/datamodel/connector";
import { useMemo } from "react";
import { ClientState, VideoPresence } from "shared/datamodel/schemas/client-state";
import { useBoardValue } from "./state/board-state";
import { getElement } from "shared/datamodel/canvas-element";
import { M } from "shared/datamodel/mutators";
import consts from "shared/consts";
import { Frame } from "shared/datamodel/schemas/frame";
import { AM, BoardMetadata } from "shared/datamodel/account-mutators";
import { Board } from "shared/datamodel/schemas/board";
import { getThumbnailId } from "shared/datamodel/metadata";
import { votingKeyPrefix } from "shared/datamodel/voting";
import { VotingSession } from "shared/datamodel/schemas/voting-session";
import { timerKey } from "shared/datamodel/timer";
import { TimerConfigSchema } from "shared/datamodel/schemas/timer";
import { getMindmapNodesForRootId, mindmapNodesForData } from "shared/datamodel/mindmap";
import { IntegrationItem } from "shared/datamodel/schemas/integration-item";
import { BoardUser, CanvasElement } from "shared/datamodel/schemas";

export function useCanvasElements(rep: Reflect<M>): {
  elementsIds: string[];
  frameIds: string[];
  groups: { [key: string]: any[] };
  commentIds: string[];
  frames: { [key: string]: any[] };
  cardStacks: { [key: string]: any[] };
  isLoaded: boolean;
  thumbnailId: string | null;
  hiddenFrameIds: string[];
  hiddenElementIds: string[];
} {
  let { elements, groups, commentElements, frames, cardStacks, isLoaded, thumbnailId, hiddenFrameIds } =
    useCanvasElementsObjects(rep);
  const elementsIds = elements.map((element) => `${element.type}-${element.id}`); // this actually shows up in the profiler !!
  const frameIds = elements
    .filter((element) => element.type === consts.CANVAS_ELEMENTS.FRAME)
    .map((element) => `${element.type}-${element.id}`);
  const commentIds = commentElements.map((element) => `comment-${element.id}`);
  const hiddenElementIds = [...hiddenFrameIds, ...hiddenFrameIds.flatMap((id) => frames[id])];
  return {
    elementsIds,
    commentIds,
    groups,
    frames,
    cardStacks,
    isLoaded,
    frameIds,
    thumbnailId,
    hiddenFrameIds,
    hiddenElementIds,
  };
}

function getPrimarySortKey(element: any) {
  switch (element.type) {
    case consts.CANVAS_ELEMENTS.CARD_STACK:
      return 1;
    case consts.CANVAS_ELEMENTS.FRAME:
      return 3;
    default:
      return 2;
  }
}

function getSecondarySortKey(element: any) {
  return element.value.zIndexLastChangeTime ?? element.value.lastModifiedTimestamp;
}

function canvasElementsSortOrder(a: any, b: any) {
  const ak = getPrimarySortKey(a);
  const bk = getPrimarySortKey(b);
  if (ak != bk) return ak - bk;
  return getSecondarySortKey(a) - getSecondarySortKey(b);
}

export function useCanvasElementsObjects(rep: Reflect<M> | null | undefined) {
  const [sortedElements, commentElements, loaded, thumbnailId] = useSubscribe(
    rep,
    async (tx) => {
      const canvasElements = [];
      const commentElements = [];
      const shapes = await tx.scan({ prefix: canvasElementPrefix }).entries().toArray();
      for (const [k, v] of shapes) {
        const [, type, id] = k.split("-", 3);
        if ("hidden" in v && v.hidden) {
          continue;
        }
        if (
          (type == consts.CANVAS_ELEMENTS.MINDMAP || type == consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART) &&
          typeof v.parentId !== "undefined"
        ) {
          // skip mindmap nodes that are not root nodes
          continue;
        }
        const element = {
          type: type,
          id: id,
          value: v,
        };
        if (type == consts.CANVAS_ELEMENTS.COMMENT) {
          commentElements.push(element);
        } else {
          canvasElements.push(element);
        }
      }
      const sortedElements = canvasElements.sort(canvasElementsSortOrder);
      const thumbnailId = await getThumbnailId(tx);
      return [sortedElements, commentElements, sortedElements.length > 0, thumbnailId] as const;
    },
    [[], [], false, null]
  );

  let groups: { [key: string]: any[] } = {},
    frames: { [key: string]: any[] } = {},
    cardStacks: { [key: string]: any[] } = {},
    hiddenFrameIds: string[] = [];
  for (const i of sortedElements) {
    if (i.type === consts.CANVAS_ELEMENTS.FRAME && !i.value.hidden) {
      const uniqueId = `${i.type}-${i.id}`;
      const { visible = true } = i.value;
      frames[uniqueId] = [];
      if (!visible) {
        hiddenFrameIds.push(uniqueId);
      }
    }
  }
  for (const i of sortedElements) {
    let uniqueId: string | null = null;
    if (i.value.groupId) {
      uniqueId = `${i.type}-${i.id}`;
      groups[i.value.groupId] ||= [];
      groups[i.value.groupId].push(uniqueId);
    }
    if (i.value.frameId) {
      if (frames[i.value.frameId]) {
        uniqueId ||= `${i.type}-${i.id}`;
        frames[i.value.frameId].push(uniqueId);
      } else {
        const { frameId, ...rest } = i.value;
        i.value = rest;
      }
    }
    if (i.value.type == "cardStack") {
      uniqueId ||= `${i.type}-${i.id}`;
      cardStacks[uniqueId] ||= [];
    }
    if (i.value.containerId) {
      uniqueId ||= `${i.type}-${i.id}`;
      cardStacks[i.value.containerId] ||= [];
      cardStacks[i.value.containerId].push(uniqueId);
    }
  }
  return {
    elements: sortedElements,
    commentElements,
    groups,
    frames,
    cardStacks,
    isLoaded: loaded,
    thumbnailId,
    hiddenFrameIds,
  } as const;
}

export function useCardStackElements(rep: Reflect<M> | null | undefined, stackId: string): [[string, any]] {
  return useSubscribe(
    rep,
    async (tx) => {
      const prefix = canvasElementPrefix + consts.CANVAS_ELEMENTS.INTEGRATION;
      const allCards = await tx.scan({ prefix }).entries().toArray();
      const stackCards = allCards.filter(
        ([k, v]: [string, any]) => (v as IntegrationItem).containerId == stackId && !v.hidden
      );
      return stackCards.map(([k, v]: [string, any]) => [k.substring(canvasElementPrefix.length), v]);
    },
    [],
    [stackId]
  );
}

export function useItemsFromReplicache(rep: Reflect<M>, prefix: string, deps?: any[]): [string, any][] {
  return useSubscribe(
    rep,
    async (tx) => {
      return tx.scan({ prefix }).entries().toArray();
    },
    [],
    deps
  );
}

export function useMindmapNodesForRoot(
  rep: Reflect<M> | undefined,
  rootId: string,
  allElements?: { [key: string]: any }
) {
  if (allElements) {
    return useMemo(() => {
      const data = Object.entries(allElements).map(([key, value]) => [`cElement-${key}`, value]);
      return mindmapNodesForData(data, rootId);
    }, [rootId]);
  }
  if (rep) {
    return useSubscribe(rep, async (tx) => await getMindmapNodesForRootId(tx, { rootId }), {
      nodes: {},
      nodesSizes: {},
    });
  }
  return { nodes: {}, nodesSizes: {} };
}

export function useCanvasMetadata(rep: Reflect<M> | null | undefined) {
  const metadata = useRawCanvasMetadata(rep);
  const result: Record<string, any> = {};
  for (const [k, v] of metadata) {
    const [, type, id] = k.split("-", 3);
    result[type] ||= [];
    result[type].push({ id, value: v });
  }
  return result;
}

export function useIntegrationTasks(rep: Reflect<M> | null | undefined): { [integrationId: string]: string[] } {
  return useSubscribe(
    rep,
    async (tx) => {
      const items = await tx
        .scan({ prefix: `${canvasElementPrefix}${consts.CANVAS_ELEMENTS.INTEGRATION}` })
        .entries()
        .toArray();
      return items.reduce((acc: { [integrationId: string]: string[] }, [key, value]: any) => {
        const element = { ...value };
        if (element.hidden) {
          return acc;
        }
        const integrationId = element.integrationId;
        const itemId = element.configuration?.itemId;
        if (!integrationId || !itemId) {
          return acc;
        }
        const newItemIds = acc[integrationId] || [];
        newItemIds.push(itemId);
        acc[integrationId] = newItemIds;
        return acc;
      }, {});
    },
    {}
  );
}

export function useRawCanvasMetadata(rep: Reflect<M> | null | undefined): [string, any][] {
  return useSubscribe(
    rep,
    async (tx) => {
      return await tx.scan({ prefix: canvasMetadataPrefix }).entries().toArray();
    },
    []
  );
}

export function processClientEntries(clientEntries: [string, any][]) {
  return clientEntries
    .map(([k, v]) => {
      if (!v.present) {
        return null;
      }
      return k.substring(clientStatePrefix.length);
    })
    .filter((k) => k)
    .map((k) => k!);
}

export function useCollaboratorIDs(rep: Reflect<M>, includeCurrent: boolean) {
  const [{ user }] = useBoardValue();
  return useSubscribe(
    rep,
    async (tx) => {
      const clientEntries = await tx.scan({ prefix: clientStatePrefix }).entries().toArray();
      const res = processClientEntries(clientEntries);
      let ids = res.filter((k) => !k.includes(tx.clientID));
      if (includeCurrent && user && user.isAnonymous) {
        ids.unshift(tx.clientID);
      }
      return ids;
    },
    []
  );
}

export function useCollaboratorAvatars(rep: Reflect<M>) {
  return useSubscribe(
    rep,
    async (tx) => {
      const values = await tx.scan({ prefix: clientStatePrefix }).toArray();
      let result: any = {};
      for (const value of values) {
        if (value.userInfo.isAnonymous == false) result[value.userInfo.id] = value.userInfo.photoURL;
      }
      return result;
    },
    {}
  );
}

interface VideoParticipantsInfo {
  others?: { clientId: string; videoPresence: VideoPresence; userInfo: BoardUser }[];
  myVideoPresence?: VideoPresence;
}

export function useCollaboratorsInVideoChat(rep: Reflect<M> | null | undefined, deps?: any[]) {
  return useSubscribe(
    rep,
    async (tx) => {
      let result: VideoParticipantsInfo = {};
      const myFullId = clientStatePrefix + tx.clientID;
      for await (const [key, value] of tx.scan({ prefix: clientStatePrefix }).entries()) {
        const clientState = value as ClientState;
        if (key == myFullId) {
          result.myVideoPresence = clientState.videoPresence;
        } else if (clientState.videoPresence?.sessionId) {
          // TODO: shouldI test clientState.present ???
          result.others ??= [];
          result.others.push({
            clientId: key,
            videoPresence: clientState.videoPresence,
            userInfo: clientState.userInfo,
          });
        }
      }
      return result;
    },
    {},
    deps
  );
}

export function useClientState(rep: Reflect<M>, clientId: string | null) {
  return useSubscribe(
    rep,
    async (tx) => {
      if (!clientId) {
        return null;
      }
      return await getClientState(tx, { id: clientId });
    },
    null,
    [clientId]
  );
}

export function useClientInfoReplicache(rep: Reflect<M>, clientID: string): ClientState | null {
  return useSubscribe(
    rep,
    async (tx) => {
      return await getClientState(tx, { id: clientID });
    },
    null
  );
}

export function useClientInfo(clientId: string, reps: Reflect<M>) {
  return useClientInfoReplicache(reps, clientId);
}

export function useConnectorsIDs(rep: Reflect<M>) {
  return useSubscribe(
    rep,
    async (tx) => {
      const connectors = await tx.scan({ prefix: connectorPrefix }).keys().toArray();
      return connectors.map((c: string) => c.split("-", 3)[2]);
    },
    []
  );
}

export function useCanvasElementById(rep: Reflect<M>, id: string, defaultValue: any = null, deps?: any[]) {
  return useSubscribe(
    rep,
    async (tx) => {
      return id ? await getElement(tx, id) : await Promise.resolve(null);
    },
    defaultValue,
    deps
  );
}

export function useCanvasElementsByIds(rep: Reflect<M>, ids: string[], deps?: any[]) {
  return useSubscribe(
    rep,
    async (tx) => {
      const getElem = (id: string) => getElement(tx, id);
      const data = await Promise.all(ids.map(getElem));
      return ids.map((id, index) => [id, data[index]]);
    },
    undefined,
    deps
  );
}

export function useSortedFrames(rep: Reflect<M>) {
  const { elements, frames: framesMap, hiddenFrameIds } = useCanvasElementsObjects(rep);
  let frames = Object.keys(framesMap).map((id) => elements.find((e) => id === `${e.type}-${e.id}`)!);
  frames.sort((f1, f2) => {
    const fr1 = f1.value as Frame;
    const fr2 = f2.value as Frame;
    const s1: number = fr1.sortIndex ?? frames.indexOf(f1)!;
    const s2: number = fr2.sortIndex ?? frames.indexOf(f2)!;
    return s1 - s2;
  });
  return { sortedFrames: frames, elements, framesMap, hiddenFrameIds };
}

export function useSortedBoards(rep: Reflect<AM>, boards: Board[]) {
  let boardsMap = boards.reduce((res, board) => {
    res.set(board.documentId, board);
    return res;
  }, new Map<string, Board>());

  function sortedBoards(boards: Board[]) {
    let sortedBoards = boards;
    sortedBoards.sort((b1, b2) => b2.updatedAt.getTime() - b1.updatedAt.getTime());
    return sortedBoards;
  }

  return useSubscribe(
    rep,
    async (tx) => {
      const boardsMeta = await tx.scan({ prefix: `board-` }).entries().toArray();
      for (const [key, value] of boardsMeta) {
        const meta = value as BoardMetadata;
        const documentId = key.replace("board-", "") as string;
        let board = boardsMap.get(documentId);
        if (board) {
          if (meta.lastChanged) {
            board.updatedAt = new Date(Math.max(meta.lastChanged, board.updatedAt.getTime()));
          }
          if (meta.snapshotImage) {
            board.thumbnail = meta.snapshotImage;
          }
          boardsMap.set(documentId, board);
        }
      }
      return sortedBoards(Array.from(boardsMap.values()));
    },
    sortedBoards(boards),
    [boards]
  );
}

export function useAllVotingSessions(rep?: Reflect<M> | null, deps?: any[]) {
  return useSubscribe(
    rep,
    async (tx: ReadTransaction) => {
      const result = await tx.scan({ prefix: votingKeyPrefix }).values().toArray();
      const arr = result as VotingSession[];
      return arr.filter(({ hidden }) => !hidden);
    },
    [],
    deps
  );
}

const findLatest = (allSessions: VotingSession[] | null) => {
  if (allSessions == null || allSessions.length == 0) {
    return null;
  }
  return allSessions.reduce((acc, cur) => (cur.start > acc.start ? cur : acc));
};

export function useVotingLatest(rep?: Reflect<M> | null, deps?: any[]) {
  const allSessions = useAllVotingSessions(rep, deps);
  return findLatest(allSessions);
}

export function useVotingCurrent(rep?: Reflect<M> | null, extraTime = 0): VotingSession | null {
  const history = useAllVotingSessions(rep);
  const latest = findLatest(history);
  if (latest == null) return null;
  if (latest.duration == undefined) return latest;
  const end = latest.start + (latest.duration + extraTime) * 1000;
  return end > Date.now() ? latest : null;
}

export function useTimerData(rep: Reflect<M>) {
  return useSubscribe(
    rep,
    async (tx: ReadTransaction) => {
      const result = await tx.get(timerKey);
      return result as TimerConfigSchema;
    },
    null
  );
}

export function useContainerElements(rep: Reflect<M> | null | undefined, containerId: string, deps?: any[]) {
  return useSubscribe(
    rep,
    async (tx: ReadTransaction) => {
      const result = await tx.scan({ prefix: canvasElementPrefix }).entries().toArray();
      const elements: Record<string, CanvasElement> = {};
      for (const [key, value] of result) {
        if (value && typeof value === "object" && "containerId" in value && value.containerId === containerId) {
          const [, type, id] = key.split("-", 3);
          const fullId = `${type}-${id}`;
          elements[fullId] = value as CanvasElement;
        }
      }
      return elements;
    },
    {},
    deps
  );
}

export function useSanityElement(rep: Reflect<M> | null | undefined) {
  return useSubscribe(
    rep,
    async (tx: ReadTransaction) => {
      const result = await tx.get(`${sanityMutationId}-${tx.clientID}`);
      if (result) {
        return result as { originTimestamp: number; sanityTimestamp?: number };
      }
      return null;
    },
    null
  );
}
